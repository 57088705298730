const vertexShader = `
  precision mediump float;
  attribute vec2 a_Position;
  attribute vec2 a_TexCoord;
  varying vec2 TexCoord;
  uniform vec2 u_Screen_Size;

  void main(){
    // vec2 position = (a_Position / u_Screen_Size) * 2.0 - 1.0;
    vec2 position = a_Position;
    // position = position * vec2(1.0, -1.0);
    gl_Position = vec4(position, 0, 1);
    TexCoord = a_TexCoord;
  }
`;
const originShader = `
precision mediump float;
varying vec2 TexCoord;
uniform sampler2D ourTexture;
uniform sampler2D ourTexture1;
void main()
{
    //原始采样像素的 RGBA 值
    vec4 textureColor = texture2D(ourTexture,TexCoord);
    if(textureColor.a < 0.1){
      discard;
    }
    //获取 B 分量值，确定 LUT 小方格的 index, 取值范围转为 0～63
    float blueColor = textureColor.b * 63.0;

    //取与 B 分量值最接近的 2 个小方格的坐标
    vec2 quad1;
    quad1.y = floor(floor(blueColor) / 8.0);
    quad1.x = floor(blueColor) - (quad1.y * 8.0);

    vec2 quad2;
    quad2.y = floor(ceil(blueColor) / 7.9999);
    quad2.x = ceil(blueColor) - (quad2.y * 8.0);

    //通过 R 和 G 分量的值确定小方格内目标映射的 RGB 组合的坐标，然后归一化，转化为纹理坐标。
    vec2 texPos1;
    texPos1.x = (quad1.x * 0.125) + 0.5/512.0 + ((0.125 - 1.0/512.0) * textureColor.r);
    texPos1.y = (quad1.y * 0.125) + 0.5/512.0 + ((0.125 - 1.0/512.0) * textureColor.g);

    vec2 texPos2;
    texPos2.x = (quad2.x * 0.125) + 0.5/512.0 + ((0.125 - 1.0/512.0) * textureColor.r);
    texPos2.y = (quad2.y * 0.125) + 0.5/512.0 + ((0.125 - 1.0/512.0) * textureColor.g);

    //取目标映射对应的像素值
    vec4 newColor1 = texture2D(ourTexture1, texPos1);
    vec4 newColor2 = texture2D(ourTexture1, texPos2);

    //使用 Mix 方法对 2 个边界像素值进行混合
    vec4 newColor = mix(newColor1, newColor2, fract(blueColor));
    gl_FragColor = mix(textureColor, vec4(newColor.rgb, textureColor.w), 1.0);
}`;
const pictureShader = {
  default: `
  precision mediump float;
  varying vec2 TexCoord;
  uniform sampler2D ourTexture;
  void main()
  {
      vec4 texColor = texture2D(ourTexture, TexCoord);
      if(texColor.a < 0.1){
        discard;
      }
      gl_FragColor = texColor;
  }`,
  //高斯模糊
  gaussianBlur: `
  precision mediump float;
  varying vec2 TexCoord;
  uniform sampler2D ourTexture;
  const int KernelSize = 9;
  const float stepValue = 0.005;
  void main() {
      vec2 uv = TexCoord;
      vec4 sum = vec4(0.0);

      //用来存储3x3的卷积矩阵
      float Kernel[KernelSize];
      Kernel[6] = 1.0; Kernel[7] = 2.0; Kernel[8] = 1.0;
      Kernel[3] = 2.0; Kernel[4] = 4.0; Kernel[5] = 2.0;
      Kernel[0] = 1.0; Kernel[1] = 2.0; Kernel[2] = 1.0;


      float fStep = stepValue;
      //像素点偏移位置
      vec2 Offset[KernelSize];
      Offset[0] = vec2(-fStep,-fStep); Offset[1] = vec2(0.0,-fStep); Offset[2] = vec2(fStep,-fStep);
      Offset[3] = vec2(-fStep,0.0);    Offset[4] = vec2(0.0,0.0);    Offset[5] = vec2(fStep,0.0);
      Offset[6] = vec2(-fStep, fStep); Offset[7] = vec2(0.0, fStep); Offset[8] = vec2(fStep, fStep);

      for (int i = 0; i < KernelSize; i++)
      {
          vec4 tmp = texture2D(ourTexture, uv + Offset[i]);
          if(tmp.a < 0.1){
            discard;
          }
          sum += tmp * Kernel[i];
      }
      gl_FragColor = sum/16.0;
  }`,
  //六边形马赛克
  hexagonMosaic: `
  precision mediump float;
  varying vec2 TexCoord;
  uniform sampler2D ourTexture;
  const float mosaicSize = 0.01;
  void main()
  {
    float rate= 2264.0 / 1080.0;
    float length = mosaicSize;
    float TR = 0.866025;

    //纹理坐标值
    float x = TexCoord.x;
    float y = TexCoord.y;

    //转化为矩阵中的坐标
    int wx = int(x / 1.5 / length);
    int wy = int(y / TR / length);
    vec2 v1, v2, vn;

    //分析矩阵中的坐标是在奇数还是在偶数行，根据奇数偶数值来确定我们的矩阵的角标坐标值
    if (wx/2 * 2 == wx) {
      if (wy/2 * 2 == wy) {
        //(0,0),(1,1)
        v1 = vec2(length * 1.5 * float(wx), length * TR * float(wy));
        v2 = vec2(length * 1.5 * float(wx + 1), length * TR * float(wy + 1));
      } else {
        //(0,1),(1,0)
        v1 = vec2(length * 1.5 * float(wx), length * TR * float(wy + 1));
        v2 = vec2(length * 1.5 * float(wx + 1), length * TR * float(wy));
      }
    }else {
      if (wy/2 * 2 == wy) {
        //(0,1),(1,0)
        v1 = vec2(length * 1.5 * float(wx), length * TR * float(wy + 1));
        v2 = vec2(length * 1.5 * float(wx + 1), length * TR * float(wy));
      } else {
        //(0,0),(1,1)
        v1 = vec2(length * 1.5 * float(wx), length * TR * float(wy));
        v2 = vec2(length * 1.5 * float(wx + 1), length * TR * float(wy + 1));
      }
    }

    //获取距离
    float s1 = sqrt(pow(v1.x - x, 2.0) + pow(v1.y - y, 2.0));
    float s2 = sqrt(pow(v2.x - x, 2.0) + pow(v2.y - y, 2.0));

    //设置具体的纹理坐标
    if (s1 < s2) {
      vn = v1;
    } else {
      vn = v2;
    }
    vec4 color = texture2D(ourTexture, vn);
    gl_FragColor = color;
  }`,
  //画中画
  pictureInPicture: `
  precision mediump float;
  varying vec2 TexCoord;
  uniform sampler2D ourTexture;
  const float OFFSET_LEVEL = 0.15;
  const float SCALE_LEVEL = 3.0;
  vec2 scale(vec2 uv, float level)
  {
      vec2 center = vec2(0.5, 0.5);
      vec2 newTexCoord = uv.xy;
      newTexCoord -= center;
      newTexCoord = newTexCoord / level;
      newTexCoord += center;
      return newTexCoord;
  }
  void main()
  {
      if(OFFSET_LEVEL < TexCoord.x && TexCoord.x < (1.0 - OFFSET_LEVEL)
         && OFFSET_LEVEL < TexCoord.y && TexCoord.y < (1.0 - OFFSET_LEVEL))
      {
          //将原图下采样到指定区域中
          vec2 newTexCoord = TexCoord;
          newTexCoord -= OFFSET_LEVEL;
          newTexCoord = newTexCoord / (1.0 - 2.0 * OFFSET_LEVEL);
          gl_FragColor = texture2D(ourTexture, newTexCoord);
      }
      else
      {
          //原纹理坐标缩放之后再进行采样
          gl_FragColor = texture2D(ourTexture, scale(TexCoord, SCALE_LEVEL));
      }
  }`,
  //灰度
  grayscale: `
  precision mediump float;
  varying vec2 TexCoord;
  uniform sampler2D ourTexture;
  const  vec3 ratio = vec3(0.2125, 0.7154, 0.0721);
  void main()
  {
      vec4 texColor = texture2D(ourTexture, TexCoord);
      if(texColor.a < 0.1){
        discard;
      }
      float luminance = dot(texColor.rgb, ratio);
      gl_FragColor = vec4(vec3(luminance), 1.0);
  }`,
  //褪色
  fade: `
  precision mediump float;
  varying vec2 TexCoord;
  uniform sampler2D ourTexture;
  float scale = 1.5;
  vec4 c = vec4(0.2124, 0.7153, 0.0722, 0.0);
  vec4 bleach(vec4 p, vec4 m, vec4 s){
      vec4 a = vec4(1.0);
      vec4 b = vec4(2.0);
      float l = dot(m, c); ///< 计算灰度

      // 提取亮部比例
      float x = clamp((l - 0.45) * 10.0, 0.0, 1.0);

      // 提升亮部、抑制暗部对比
      vec4 t = b * m * p;

      // 提升暗部、抑制亮部对比
      vec4 w = a - (b * (a - m) * (a - p));

      // 根据亮部比例混合
      vec4 r = mix(t, w, vec4(x));

      // 和原图进行混合
      return mix(m, r, s);
  }
  void main()
  {
      vec4 m = texture2D(ourTexture, TexCoord);
      vec4 p = vec4(vec3(dot(m, c)), m.a); ///< 灰度图 + 透明度
      gl_FragColor = bleach(p, m, vec4(scale));
  }`,
  //四分屏
  quadSplitScreeny: `
  precision mediump float;
  varying vec2 TexCoord;
  uniform sampler2D ourTexture;
  void main() {
      vec2 uv = TexCoord.xy;
      if (uv.x <= 0.5) {
          uv.x = uv.x * 2.0;
      }else {
          uv.x = (uv.x - 0.5) * 2.0;
      }
      if (uv.y <= 0.5) {
          uv.y = uv.y * 2.0;
      }else {
          uv.y = (uv.y - 0.5) * 2.0;
      }
      gl_FragColor = texture2D(ourTexture, uv);
  }`,
  //矩形马赛克
  rectMosaic: `
  precision mediump float;
  //纹理坐标
  varying vec2 TexCoord;
  //纹理采样器
  uniform sampler2D ourTexture;
  //纹理图片size
  uniform vec2 u_Screen_Size;
  //马赛克size
  vec2 mosaicSize = vec2(u_Screen_Size.x * 1.0 / 25.0, u_Screen_Size.x * 1.0 / 25.0);
  
  void main()
  {
      //计算图像的实际位置
      vec2 intXY = vec2(TexCoord.x*u_Screen_Size.x, TexCoord.y*u_Screen_Size.y);
      // floor (x) 内建函数,返回小于/等于X的最大整数值.
      // floor (intXY.x / mosaicSize.x) * mosaicSize.x 计算出一个⼩小⻢赛克的坐标.
      vec2 XYMosaic = vec2(floor(intXY.x/mosaicSize.x)*mosaicSize.x, floor(intXY.y/mosaicSize.y)*mosaicSize.y);
      //换算回纹理坐标
      vec2 UVMosaic = vec2(XYMosaic.x/u_Screen_Size.x, XYMosaic.y/u_Screen_Size.y);
      //获取到马赛克后的纹理坐标的颜色值
      vec4 color = texture2D(ourTexture, UVMosaic);
      //将⻢赛克颜色值赋值给gl_FragColor.
      gl_FragColor = color;
  }`,
  //三角形马赛克
  triangleMosaic: `
  precision highp float;
uniform sampler2D ourTexture;
varying vec2 TexCoord;

float mosaicSize = 0.03;

void main (void){
    const float TR = 0.866025;
    const float PI6 = 0.523599;

    float x = TexCoord.x;
    float y = TexCoord.y;

    int wx = int(x/(1.5 * mosaicSize));
    int wy = int(y/(TR * mosaicSize));

    vec2 v1, v2, vn;

    if (wx / 2 * 2 == wx) {
        if (wy/2 * 2 == wy) {
            v1 = vec2(mosaicSize * 1.5 * float(wx), mosaicSize * TR * float(wy));
            v2 = vec2(mosaicSize * 1.5 * float(wx + 1), mosaicSize * TR * float(wy + 1));
        } else {
            v1 = vec2(mosaicSize * 1.5 * float(wx), mosaicSize * TR * float(wy + 1));
            v2 = vec2(mosaicSize * 1.5 * float(wx + 1), mosaicSize * TR * float(wy));
        }
    } else {
        if (wy/2 * 2 == wy) {
            v1 = vec2(mosaicSize * 1.5 * float(wx), mosaicSize * TR * float(wy + 1));
            v2 = vec2(mosaicSize * 1.5 * float(wx+1), mosaicSize * TR * float(wy));
        } else {
            v1 = vec2(mosaicSize * 1.5 * float(wx), mosaicSize * TR * float(wy));
            v2 = vec2(mosaicSize * 1.5 * float(wx + 1), mosaicSize * TR * float(wy+1));
        }
    }

    float s1 = sqrt(pow(v1.x - x, 2.0) + pow(v1.y - y, 2.0));
    float s2 = sqrt(pow(v2.x - x, 2.0) + pow(v2.y - y, 2.0));

    if (s1 < s2) {
        vn = v1;
    } else {
        vn = v2;
    }

    vec4 mid = texture2D(ourTexture, vn);
    float a = atan((x - vn.x)/(y - vn.y));

    vec2 area1 = vec2(vn.x, vn.y - mosaicSize * TR / 2.0);
    vec2 area2 = vec2(vn.x + mosaicSize / 2.0, vn.y - mosaicSize * TR / 2.0);
    vec2 area3 = vec2(vn.x + mosaicSize / 2.0, vn.y + mosaicSize * TR / 2.0);
    vec2 area4 = vec2(vn.x, vn.y + mosaicSize * TR / 2.0);
    vec2 area5 = vec2(vn.x - mosaicSize / 2.0, vn.y + mosaicSize * TR / 2.0);
    vec2 area6 = vec2(vn.x - mosaicSize / 2.0, vn.y - mosaicSize * TR / 2.0);


    if (a >= PI6 && a < PI6 * 3.0) {
        vn = area1;
    } else if (a >= PI6 * 3.0 && a < PI6 * 5.0) {
        vn = area2;
    } else if ((a >= PI6 * 5.0 && a <= PI6 * 6.0) || (a < -PI6 * 5.0 && a > -PI6 * 6.0)) {
        vn = area3;
    } else if (a < -PI6 * 3.0 && a >= -PI6 * 5.0) {
        vn = area4;
    } else if(a <= -PI6 && a> -PI6 * 3.0) {
        vn = area5;
    } else if (a > -PI6 && a < PI6) {
        vn = area6;
    }

    vec4 color = texture2D(ourTexture, vn);
    gl_FragColor = color;
}`,
  // 水粉画
  Gouache: `
  //水粉画
                            precision highp float;
                            varying vec2 TexCoord;
                            uniform sampler2D ourTexture;
                            const int radius = 5;
                            const vec2 src_size = vec2 (1.0 / 768.0, 1.0 / 1024.0);
                            void main (void)
                            {
                                vec2 uv = TexCoord;
                                float n = float((radius + 1) * (radius + 1));
                                vec3 m0 = vec3(0.0); vec3 m1 = vec3(0.0); vec3 m2 = vec3(0.0); vec3 m3 = vec3(0.0);
                                vec3 s0 = vec3(0.0); vec3 s1 = vec3(0.0); vec3 s2 = vec3(0.0); vec3 s3 = vec3(0.0);
                                vec4 c;
                                for (int j = -radius; j <= 0; ++j)  {
                                    for (int i = -radius; i <= 0; ++i)  {
                                        c = texture2D(ourTexture, uv + vec2(i,j) * src_size);
                                        if(c.a < 0.1){
                                            discard;
                                        }
                                        m0 += c.rgb;
                                        s0 += c.rgb * c.rgb;
                                    }
                                }
                                for (int j = -radius; j <= 0; ++j)  {
                                    for (int i = 0; i <= radius; ++i)  {
                                        c = texture2D(ourTexture, uv + vec2(i,j) * src_size);
                                        m1 += c.rgb;
                                        s1 += c.rgb * c.rgb;
                                    }
                                }
                                for (int j = 0; j <= radius; ++j)  {
                                    for (int i = 0; i <= radius; ++i)  {
                                        c = texture2D(ourTexture, uv + vec2(i,j) * src_size);
                                        m2 += c.rgb;
                                        s2 += c.rgb * c.rgb;
                                    }
                                }
                                for (int j = 0; j <= radius; ++j)  {
                                    for (int i = -radius; i <= 0; ++i)  {
                                        c = texture2D(ourTexture, uv + vec2(i,j) * src_size);
                                        m3 += c.rgb;
                                        s3 += c.rgb * c.rgb;
                                    }
                                }
                                float min_sigma2 = 1e+2;
                                m0 /= n;
                                s0 = abs(s0 / n - m0 * m0);
                                float sigma2 = s0.r + s0.g + s0.b;
                                if (sigma2 < min_sigma2) {
                                    min_sigma2 = sigma2;
                                    gl_FragColor = vec4(m0, 1.0);
                                }
                                m1 /= n;
                                s1 = abs(s1 / n - m1 * m1);
                                sigma2 = s1.r + s1.g + s1.b;
                                if (sigma2 < min_sigma2) {
                                    min_sigma2 = sigma2;
                                    gl_FragColor = vec4(m1, 1.0);
                                }
                                m2 /= n;
                                s2 = abs(s2 / n - m2 * m2);
                                sigma2 = s2.r + s2.g + s2.b;
                                if (sigma2 < min_sigma2) {
                                    min_sigma2 = sigma2;
                                    gl_FragColor = vec4(m2, 1.0);
                                }
                                m3 /= n;
                                s3 = abs(s3 / n - m3 * m3);
                                sigma2 = s3.r + s3.g + s3.b;
                                if (sigma2 < min_sigma2) {
                                    min_sigma2 = sigma2;
                                    gl_FragColor = vec4(m3, 1.0);
                                }
                            }`,
  //老旧显示屏
  oldMonitor: `//老旧显示屏
  precision highp float;
  varying vec2 TexCoord;
  uniform sampler2D ourTexture;
  uniform vec2 u_Screen_Size;
  uniform float Time;
  #define tau 6.28318530718
  #define cr vec2(4.,0.)
  #define cb vec2(2.,0.)
  #define cg vec2(0.,0.)
  #define cw vec2(3.,1.)
  //CRT curvature
  const float cus = 0.15;
  //Vignette strength
  const float vstr = .05;
  //Display margin
  const float marginv = .02;
  //Phosper size (should be an integer)
  const float dts = 1.;
  //De-moire convolution iteration (if you see moire, up this value)
  const float AAz = 64.;
  //De-moire convolution width (don't touch if you have no idea)
  const float vex = 2.;
  //Capacitance (scanline horizontal blur)
  const float capa = 1.;
  //Capacitnace iteration
  const float capaiter  = 5.0;
  //Capacitance IR shape parameter
  const float capashape = 3.0;
  //Scanline count
  const float scanline =240.;
  //Gamma correction
  const float gma = 1.0;
  //Line bleed
  const float sling = 2.0;
  float sawtooth(float inp){
      return inp-floor(inp);
  }
  float square(float zed,float marchpoint,float floaz){
      return step(sawtooth(zed/floaz),marchpoint/floaz);
  }
  float triangle(float zed){
      return abs(sawtooth(zed+0.5)-.5)*2.;
  }
  vec2 hash22(vec2 seed){
      return fract(75.345*sin(623.3754*seed));
  }
  float grd(vec2 uv, vec2 disp){
      uv +=disp*dts;
      uv /= dts;
      return square(uv.x,2.,6.)*square(uv.y,1.,2.);
  }
  vec3 tpscany (vec3 bef,vec3 ucj,vec3 dcj,float temp){
      vec3 scan = vec3(.0);
      scan += max((triangle(temp)-1.+(bef*sling)),.0);
      scan += max((clamp(.0,1.,temp*2.-1.)-2.)+(ucj*sling),.0);
      scan += max((clamp(.0,1.,-(temp*2.-1.))-2.)+(dcj*sling),.0);
      return scan/(sling*0.5);
  }
  void pinc(vec2 uv, inout vec2 uv2, inout float mxbf, inout float vign, float ar){
      uv2 = (uv*vec2(2.)-vec2(1.))*vec2((1.+marginv),(1.+marginv*ar));
      uv2 = vec2(uv2.x/(cos(abs(uv2.y*cus)*tau/4.)),uv2.y/(cos(abs(uv2.x*cus*ar)*tau/4.)));
      vec2 uvbef = abs(uv2)-vec2(1.);//boarder
      mxbf = max(uvbef.x,uvbef.y);
      vign = max(uvbef.x*uvbef.y,.0);
      uv2 = (uv2+vec2(1.))*vec2(.5);//recoordination
  }
  float scimpresp(float range){//scanline IR
      return sin(pow(range,capashape)*tau)+1.;
  }
  void main(){
      vec2 TexCoord = TexCoord * u_Screen_Size;
      vec2 uv2;
      float mxbf, vign;
      vec2 ratd = TexCoord;
      vec2 uv = TexCoord/u_Screen_Size.xy;
      pinc(uv,uv2,mxbf,vign,u_Screen_Size.x/u_Screen_Size.y);
      vec2 nuv = uv2;
      vec2 nuvyud = vec2(floor(nuv.y*scanline-1.)/scanline,floor(nuv.y*scanline+1.)/scanline);
      nuv.y = floor(nuv.y*scanline)/scanline;
      vec4 bef = vec4(.0);
      vec4 ucj = vec4(.0);
      vec4 dcj = vec4(.0);
      float capatemp, capainteg = .0;
      for(float i=-capaiter/2.;i<capaiter/2.;i++){
          capatemp = scimpresp((i+capaiter/2.)/capaiter);
          capainteg += capatemp;
          bef += texture2D(ourTexture,vec2(sawtooth(nuv.x-capa/scanline*i/(capaiter/2.)),nuv.y))*capatemp;
          if(bef.a < 0.1){
              discard;
          }
          ucj += texture2D(ourTexture,vec2(sawtooth(nuv.x-capa/scanline*i/(capaiter/2.)),nuvyud.y))*capatemp;
          dcj += texture2D(ourTexture,vec2(sawtooth(nuv.x-capa/scanline*i/(capaiter/2.)),nuvyud.x))*capatemp;
      }
      dcj.rgb /= capainteg;
      bef.rgb /= capainteg;
      ucj.rgb /= capainteg;
      vec3 scan =vec3(.0);
      float temp;
      float snippet;
      float integral = .0;
      for(float i = -AAz/2.; i<=AAz/2. ;i++){
          snippet = (AAz/2.-abs(i))/AAz/2.;
          integral += snippet;
          temp = sawtooth(uv2.y*scanline);
          scan += tpscany(bef.rgb,ucj.rgb,dcj.rgb,temp+(i/AAz*2.)*vex/u_Screen_Size.y*scanline)*snippet;//antimoire convololution
      }
      scan /= integral;
      float brd = step(mxbf,.0);
      vign = pow(vign,vstr);
      vec3 grid = vec3(grd(ratd,cr),grd(ratd,cg),grd(ratd,cb));
      grid += vec3(grd(ratd,cr+cw),grd(ratd,cg+cw),grd(ratd,cb+cw));
      float mask = brd*vign;
      scan /= sling;
      scan = pow(scan,vec3(0.5));
      scan = pow(scan,vec3(1.+1./3.));
      scan = pow(scan,vec3(gma));
      vec3 grided = scan*grid*3.;
      vec3 final = min(vec3(mix(grided,scan,scan)),vec3(1.))*mask;
      gl_FragColor = vec4(final,1.);
  }`,
  //浮雕
  relief: `
  precision highp float;
varying vec2 TexCoord;
uniform sampler2D ourTexture;
uniform vec2 u_Screen_Size;
const highp vec3 W = vec3(0.2125, 0.7154, 0.0721);
const vec4 bkColor = vec4(0.5, 0.5, 0.5, 1.0);

void main()
{
    float scale = u_Screen_Size.x / 500.0 * 2.0;
    vec2 upLeftUV = vec2(TexCoord.x-1.0/u_Screen_Size.x * scale, TexCoord.y-1.0/u_Screen_Size.y * scale);
    vec4 curColor = texture2D(ourTexture, TexCoord);
    vec4 upLeftColor = texture2D(ourTexture, upLeftUV);
    vec4 delColor = curColor - upLeftColor;
    float luminance = dot(delColor.rgb, W);
    gl_FragColor = vec4(vec3(luminance), 0.0) + bkColor;
}`,
  //黑白点阵
  blackWhiteMatrix: `//浮点数设置为中等精度
  precision mediump float;
  varying vec2 TexCoord;
  uniform sampler2D ourTexture;
  uniform vec2 u_Screen_Size;
  const int lookupSize = 64;
  const float errorCarry = 0.3;

  float getGrayscale(vec2 coords){
      vec2 uv = coords / u_Screen_Size.xy;
      vec4 sourcePixel = texture2D(ourTexture, uv);
      if(sourcePixel.a < 0.1){
          sourcePixel.rgb = vec3(1., 1., 1.);
      }
      return length(sourcePixel.rgb*vec3(0.2126,0.7152,0.0722));
  }
  void main() {
      vec2 TexCoord = TexCoord * u_Screen_Size.xy;
      int topGapY = int(u_Screen_Size.y - TexCoord.y);
      int cornerGapX = int((TexCoord.x < 10.0) ? TexCoord.x : u_Screen_Size.x - TexCoord.x);
      int cornerGapY = int((TexCoord.y < 10.0) ? TexCoord.y : u_Screen_Size.y - TexCoord.y);
      int cornerThreshhold = ((cornerGapX == 0) || (topGapY == 0)) ? 5 : 4;
      if (cornerGapX+cornerGapY < cornerThreshhold) {
          gl_FragColor = vec4(0,0,0,1);
      }
      else {
          float xError = 0.0;
          for(int xLook=0; xLook<lookupSize; xLook++){
              float grayscale = getGrayscale(TexCoord.xy + vec2(-lookupSize+xLook,0));
              grayscale += xError;
              float bit = grayscale >= 0.5 ? 1.0 : 0.0;
              xError = (grayscale - bit)*errorCarry;
          }
          float yError = 0.0;
          for(int yLook=0; yLook<lookupSize; yLook++){
              float grayscale = getGrayscale(TexCoord.xy + vec2(0,-lookupSize+yLook));
              grayscale += yError;
              float bit = grayscale >= 0.5 ? 1.0 : 0.0;
              yError = (grayscale - bit)*errorCarry;
          }
          float finalGrayscale = getGrayscale(TexCoord.xy);
          finalGrayscale += xError*0.5 + yError*0.5;
          float finalBit = finalGrayscale >= 0.5 ? 1.0 : 0.0;
          gl_FragColor = vec4(finalBit,finalBit,finalBit,1);
      }
  }`,
};

const filterConfig = [
  //
  {
    id: "filter_default",
    vertexShader: vertexShader,
    fragmentShader: pictureShader.default,
    lut: "",
  },
  //水粉画
  {
    id: "filter1",
    vertexShader: vertexShader,
    fragmentShader: originShader,
    name: "Bright",
    lut: "/ai_tools/luts/ldMb5FmLuFFErSyJq29GOx.webp",
  },
  //六边形马赛克
  {
    id: "filter2",
    name: "Fading",
    vertexShader: vertexShader,
    fragmentShader: originShader,
    lut: "/ai_tools/luts/4gOfPg09pJtt6FQOzrkfwa.webp",
  },
  //画中画
  {
    id: "filter3",
    name: "Frame",
    vertexShader: vertexShader,
    fragmentShader: pictureShader.pictureInPicture,
    lut: "",
  },
  //灰度
  {
    id: "filter4",
    name: "Four Screen",
    vertexShader: vertexShader,
    fragmentShader: pictureShader.quadSplitScreeny,
    lut: "",
  },
  // //褪色
  {
    id: "filter5",
    name: "High Contrast",
    vertexShader: vertexShader,
    fragmentShader: originShader,
    lut: "/ai_tools/luts/t1BmOc9y2fF4vzlQARliDV.webp",
  },
  //四分屏
  {
    id: "filter6",
    name: "Cold",
    vertexShader: vertexShader,
    fragmentShader: originShader,
    lut: "/ai_tools/luts/gDEw8CCB8GruK6B3zNIidk.webp",
  },
  // 立体质感
  {
    id: "filter7",
    name: "Dusk",
    vertexShader: vertexShader,
    fragmentShader: originShader,
    lut: "/ai_tools/luts/4PaGTtE47Ato9aGDzCv2n3.webp",
  },
  // 电影质感
  {
    id: "filter8",
    name: "Peach",
    vertexShader: vertexShader,
    fragmentShader: originShader,
    lut: "/ai_tools/luts/gDEw8CCB8GruK6B3zNIidk.webp",
  },
  // 英伦复古
  {
    id: "filter9",
    name: "Sunset",
    vertexShader: vertexShader,
    fragmentShader: originShader,
    lut: "/ai_tools/luts/eIBN3HcHR89DtRfzBTkpQG.webp",
  },
  // 法式鲜艳
  {
    id: "filter10",
    name: "Firelight",
    vertexShader: vertexShader,
    fragmentShader: originShader,
    lut: "/ai_tools/luts/d5tamJ5JKo7MHbkRlySMTL.webp",
  },
  //冷调森系
  {
    id: "filter11",
    name: "Jazz",
    vertexShader: vertexShader,
    fragmentShader: originShader,
    lut: "/ai_tools/luts/ff6JkHPUkrVlF3FjRTveQI.webp",
  },
  //棚拍写真
  {
    id: "filter12",
    name: "Vintage",
    vertexShader: vertexShader,
    fragmentShader: originShader,
    lut: "/ai_tools/luts/1xDaRNjSdqly5hAm7uwJEN.webp",
  },
  //山水高饱和
  {
    id: "filter13",
    name: "Relief",
    vertexShader: vertexShader,
    fragmentShader: pictureShader.relief,
    lut: "",
  },
  //灰度-silverstone
  {
    id: "filter14",
    name: "TV Lines",
    vertexShader: vertexShader,
    fragmentShader: pictureShader.oldMonitor,
    lut: "",
  },
  // 灰度-noir
  {
    id: "filter15",
    name: "Blur",
    vertexShader: vertexShader,
    fragmentShader: pictureShader.gaussianBlur,
    lut: "",
  },
  //人脸气色
  {
    id: "filter16",
    name: "Mosaic I",
    vertexShader: vertexShader,
    fragmentShader: pictureShader.hexagonMosaic,
    lut: "",
  },
  //浮雕
  {
    id: "filter17",
    name: "Mosaic II",
    vertexShader: vertexShader,
    fragmentShader: pictureShader.triangleMosaic,
    lut: "",
  },
  //矩阵马赛克
  {
    id: "filter18",
    name: "Mosaic III",
    vertexShader: vertexShader,
    fragmentShader: pictureShader.rectMosaic,
    lut: "",
  },
  //三角形马赛克
  {
    id: "filter19",
    name: "Oil Painting",
    vertexShader: vertexShader,
    fragmentShader: pictureShader.Gouache,
    lut: "",
  },
  //高斯模糊
  {
    id: "filter20",
    name: "B&W II",
    vertexShader: vertexShader,
    fragmentShader: originShader,
    lut: "/ai_tools/luts/fJ9F8ecbgts3ji6TfmxEtb.webp",
  },
  //老旧显示器
  {
    id: "filter21",
    name: "B&W III",
    vertexShader: vertexShader,
    fragmentShader: originShader,
    lut: "/ai_tools/luts/9dF3sP4AP9kuhsKCxPfm9z.webp",
  },
  //高饱和
  {
    id: "filter22",
    name: "Exposure",
    vertexShader: vertexShader,
    fragmentShader: originShader,
    lut: "/ai_tools/luts/aM3gLfGRl9HI8NC3TSeL22.webp",
  },
  //   filter12 琥珀
  {
    id: "filter23",
    name: "Gray",
    vertexShader: vertexShader,
    fragmentShader: originShader,
    lut: "/ai_tools/luts/nKHAOM0sevbkQgLCdlg9Or.webp",
  },
  //    filter13 暗雅
  {
    id: "filter24",
    name: "Friends",
    vertexShader: vertexShader,
    fragmentShader: pictureShader.fade,
    lut: "",
  },
  //    filter14 金秋
  {
    id: "filter25",
    vertexShader: vertexShader,
    fragmentShader: originShader,
    lut: "/ai_tools/luts/ptwvjC1ssqE5JmM58wxMbL.webp",

  },
  //    filter15 幽蓝
  {
    id: "filter26",
    name: "Retro",
    vertexShader: vertexShader,
    fragmentShader: originShader,
    lut: "/ai_tools/luts/nNDITR5Txgqs1D9CQy9rjf.webp",
  },
  //  幽静
  {
    id: "filter27",
    name: "Vapor",
    vertexShader: vertexShader,
    fragmentShader: originShader,
    lut: "/ai_tools/luts/3DVIzi6GU66rIvLH01eNev.webp",
  },
  //   冷淡
  {
    id: "filter28",
    name: "VHS I",
    vertexShader: vertexShader,
    fragmentShader: originShader,
    lut: "/ai_tools/luts/b4N0RyHarcA0MrJBNjPfRm.webp",
  },
  //  亮白
  {
    id: "filter29",
    name: "VHS II",
    vertexShader: vertexShader,
    fragmentShader: originShader,
    lut: "/ai_tools/luts/47h0sHiCLLIkmrg6ImbKE1.webp",
  },
  //  暗淡
  {
    id: "filter30",
    name: "VHS III",
    vertexShader: vertexShader,
    fragmentShader: originShader,
    lut: "/ai_tools/luts/vjiG4rSbPe482zQUfcTHo4.webp",
  },
  //   秋叶
  {
    id: "filter31",
    name: "Metal",
    vertexShader: vertexShader,
    fragmentShader: originShader,
    lut: "/ai_tools/luts/kHdH1UgM6Ur89DSFNQsCQh.webp",
  },
  //  翠绿
  {
    id: "filter32",
    name: "Warm",
    vertexShader: vertexShader,
    fragmentShader: originShader,
    lut: "/ai_tools/luts/oQ60Ua1alI7jAStUztMLrl.webp",
  },
  //黑白点阵
  {
    id: "filter33",
    name: "Comic",
    vertexShader: vertexShader,
    fragmentShader: pictureShader.blackWhiteMatrix,
    lut: "",
  },
];
export { filterConfig };
