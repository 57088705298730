<template>
  <div>
    <!-- <loading-mask v-show="loading" /> -->
    <!-- 图片压缩 -->
    <image-filter v-if="uploadComplete" :imageObj="uploadImageInfo" @back="handleBack" @processed="handleProcessed" />
    <image-upload v-else @uploadComplete="handleUploadComplete" />
  </div>
</template>
<script setup>
import ImageUpload from "./image-upload.vue";
import imageFilter from "@/components/models/imageFilter.vue";
import { ref, onMounted } from "vue";
import LoadingMask from "@/components/boolv-ui/loading-mask/index.vue";
import { eventTracking } from "@/utils/eventTracking.js";
import { useCheckResourceStore } from "@/store";
import { authenticator } from "@/utils";

const { deductResourceFn } = useCheckResourceStore();
const uploadComplete = ref(false);
const uploadImageInfo = ref({});
const loading = ref(false);

const handleUploadComplete = async (imageObj) => {
  // loading.value = true;
  eventTracking("booltool_page_upload", { tool_name: "filter", is_batch: false })
  await deductResourceFn('PHOTO_FILTERS_EFFECTS', false);
  await authenticator.refresh();
  uploadComplete.value = true;
  uploadImageInfo.value = imageObj;
};

const handleBack = () => {
  uploadImageInfo.value = {};
  uploadComplete.value = false;
};
const handleProcessed = () => {
  loading.value = false;
}
</script>

<style lang="less">

</style>
