<template>
  <div class="back-ground-remover-wrap">
    <div class="header flex justify-start items-center">
      <back-btn @back="handleBack" />
    </div>
    <!-- 图片滤镜 -->
    <div class="imageFilter-container" :style="{ marginTop: minClient ? '24px' : '49px' }">
      <div class="canvas-show-container position-box" @mouseenter="handleMattingBoxMouseEnter"
        @mouseleave="handleMattingBoxMouseLeave">
        <image-board width="522px" v-if="modelInProgress === 'start'">
          <img crossOrigin :src="imageObj.url" />
        </image-board>
        <canvas id="previewer" v-else></canvas>
        <div class="buttons-container" v-if="showMattingEditBtns">
          <a-button type="primary" @click="downloadImage()">Download</a-button>
        </div>

        <div class="loading-mask-box" v-if="modelInProgress === 'start'"></div>
      </div>

      <div class="imageFilter-preview-container">
        <ul class="canvas-show-ul">
          <li class="canvas-show-li" v-for="(filter, index) in imageUrlConfig" :key="filter.id" v-show="index != 0"
            @click="handlePreviewClick(filter.id, index)">
            <div class="filter-image">
              <div class="canvas-show-hover" v-if="currentSelectItem === index"></div>
              <img v-if="filter.imgUrl" :src="filter.imgUrl" width="76" :height="rightImageHeight"
                class="canvas-show-image" :style="{ width: '76px', height: rightImageHeight + 'px' }" />
            </div>
            <p class="filter-name">{{ filter.name }}</p>
          </li>
          <div class="placeholder-box"></div>
          <div class="placeholder-box"></div>
          <div class="placeholder-box"></div>
        </ul>
        <canvas id="render" v-show="false"></canvas>
      </div>

      <common-rate @changeRate="handleMattingChangeRate" :clearRate="clearRate" ref="rate" />
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, onMounted, defineEmits } from "vue";
import filterPainter from "../../utils/webglRender/filterPainter.js";
import { filterConfig } from "../../utils/webglRender/imageFilter.js";
import CommonRate from "../common-rate.vue";
import { eventTracking } from "@/utils/eventTracking.js";
import BackBtn from "@/components/boolv-ui/back-btn";
import ImageBoard from "@/components/boolv-ui/image-board";
import { storeToRefs } from 'pinia'
import { useSideStore, useViewStore } from '@/store/index.js'
import { postCanvasToUrl } from "../../utils/eventTracking";
import { MessageLoading, destroy, MessageSuccess, MessageError } from "@/components/boolv-ui/Message/index.js";
const sideStore = useSideStore()
const viewStore = useViewStore()
const { minClient } = storeToRefs(sideStore)

const props = defineProps({
  imageObj: Object,
});
var md5 = require("md5");

const visible = ref(true);
const uploadPlaceholder = ref("");
const currentImgUrl = ref("");
const currentMattingSelectRate = ref({});
const imageHandleComplete = ref(false);
const showMattingEditBtns = ref(false);
const canvasWidth = ref(522);
const canvasHeight = ref(522);
const imgMultiple = ref(1);
const modelInProgress = ref("start");
const currentSelectItem = ref(0);
const imageUrlConfig = ref(filterConfig);
const canvasNum = ref(0);
const currentFilterId = ref(-1);
const clearRate = ref(false);
const rate = ref(null);
const rightImageHeight = ref(76);
const isPreview = ref(false)

const emits = defineEmits(["back", "processed"]);

onMounted(() => {
  MessageLoading("Processing, it will take a while", 0, "", null, false);
  // viewStore.setMinViewWidth('1400px')
  draw(props.imageObj.url);
  //预加载lut图
  filterConfig.forEach((ele) => {
    if (ele.lut) {
      preloadImage(ele.lut);
    }
  });
});

const preloadImage = (url) => {
  var img = new Image();
  img.src = url;
};

const renderLiCanvas = (filterConfig, url, index) => {
  if (!filterConfig || !filterConfig.length) {
    return;
  }
  // if (index == 0) return

  //在渲染右侧 预览的时候去分批次加载所有的滤镜，加载完毕后截图，删除这个webgl上下文
  const filter = filterConfig[index];
  // console.log(`[ 准备开始${filter.id}的渲染任务] >`);
  filterPainter(
    `#${filter.id}`,
    76,
    76,
    filter.vertexShader,
    filter.fragmentShader,
    url,
    filter.lut,
    false,
    webglLoadDone,
    true
  );
};

const draw = (url) => {
  clearCanvas();
  currentImgUrl.value = url;
  renderLiCanvas(filterConfig, url, 0);
  if (imageHandleComplete.value) {
    imageHandleComplete.value = false;
  }
};

const handlePreviewClick = async (id, index) => {
  isPreview.value = true
  currentSelectItem.value = index;
  eventTracking('booltool_page_edit', { is_batch: false, edit_type: 'filter', asset_id: `asset${currentSelectItem.value}` });
  await drowAfterLoadImage(currentImgUrl.value, index + 1, false, false);
  postCanvasToUrl([document.getElementById("previewer")]);
};

const downloadImage = async () => {
  visible.value = true;
  var link = document.createElement("a");
  link.download = `photo-effects-${md5(Date.now())}`;
  link.href = document.getElementById("previewer").toDataURL();
  link.click();
  await postCanvasToUrl([document.getElementById("previewer")]);
  eventTracking("booltool_page_download", { tool_name: "filter", is_batch: false, is_success: true, asset_id: `asset${currentFilterId.value || 0}` })
};

const handleMattingChangeRate = (item) => {
  const emoji = ['star_struck', 'face_blowing_a_kiss', 'thinking_face', 'face_vomiting'];
  currentMattingSelectRate.value = item;
  eventTracking('booltool_page_feedback', { tool_name: 'filter', emoji_str: emoji[item.id - 1] });
};

const handleMattingBoxMouseEnter = () => {
  showMattingEditBtns.value = true;
};

const handleMattingBoxMouseLeave = () => {
  showMattingEditBtns.value = false;
};

const getImageFromCanvas = (filterId) => {
  const canvas = document.getElementById("render");
  console.log("[ canvas.width ] >", canvas.width);
  const dataURL = canvas.toDataURL();
  imageUrlConfig.value.forEach((filter) => {
    if (filterId == filter.id) {
      // console.log(filter)
      filter.imgUrl = dataURL;
      // document.getElementById(filterId).remove();
      // console.log(`[ 删除${filterId}的上下文，开始渲染下一个]>`);
    }
  });
};

const measureImageSize = (currentImgUrl) => {
  let img = new Image();
  img.src = currentImgUrl
  img.crossOrigin = "anonymous";
  img.onload = function () {
    const { imgWidth, imgHeight } = showImage(img);
    canvasWidth.value = imgWidth;
    canvasHeight.value = imgHeight;
    rightImageHeight.value = (imgHeight * 76) / imgWidth;
  };
}
const webglLoadDone = (id) => {
  // console.log(`[ 完成${id}的渲染任务，开始截图 ] >`);
  const filterId = id.replace("#", "");
  //截图 删除上下文
  getImageFromCanvas(filterId);
  // if (canvasNum.value == filterConfig.length - 1) {
  // console.log("全部加载完毕");

  measureImageSize(currentImgUrl.value)

  if (canvasNum.value == 18) {
    drowAfterLoadImage(currentImgUrl.value);
    emits("processed")
    imageHandleComplete.value = true;
    modelInProgress.value = "done";
    uploadPlaceholder.value = "Change an image here";
  }
  const index = canvasNum.value;
  setTimeout(() => {
    renderLiCanvas(filterConfig, currentImgUrl.value, index + 1);
  }, 0);

  canvasNum.value += 1;
};

const drowAfterLoadImage = (url, index, isClear, isRenderList) => {
  let img = new Image();
  img.crossOrigin = "anonymous";
  const filterIndex = index ? index - 1 : 0;
  const clear = isClear === false ? false : true;
  img.onload = function () {
    const { imgWidth, imgHeight } = showImage(img);
    canvasWidth.value = imgWidth;
    canvasHeight.value = imgHeight;
    rightImageHeight.value = (imgHeight * 76) / imgWidth;
    currentImgUrl.value = url;
    currentFilterId.value = index;
    filterPainter(
      "#previewer",
      imgWidth,
      imgHeight,
      filterConfig[filterIndex].vertexShader,
      filterConfig[filterIndex].fragmentShader,
      url,
      filterConfig[filterIndex].lut,
      clear,
      webglLoadDone,
      false
    );
  };
  img.src = url;

  if (isPreview.value) {
    return
  }
  MessageSuccess("Processing completed");
  setTimeout(() => {
    destroy()
  }, 1000)
};

const handleBack = () => {
  emits("back");
};

// 图片缩放
const showImage = (image) => {
  if (image.width > 522 || image.height > 522) {
    // 长 > 宽
    if (522 / image.width > 522 / image.height) {
      if (522 / image.width < 1 && 522 / image.height < 1) {
        imgMultiple.value = 522 / image.height;
      } else if (522 / image.width > 1 && 522 / image.height < 1) {
        imgMultiple.value = 522 / image.height;
      }
    } else if (522 / image.width < 522 / image.height) {
      // 宽 > 长
      if (522 / image.width < 1 && 522 / image.height < 1) {
        imgMultiple.value = 522 / image.width;
      } else if (522 / image.width < 1 && 522 / image.height > 1) {
        imgMultiple.value = 522 / image.width;
      }
    } else {
      imgMultiple.value = 522 / image.width;
    }
  } else if (image.width < 522 && image.height < 522) {
    if (522 / image.width > 522 / image.height) {
      imgMultiple.value = 522 / image.height;
    } else if (522 / image.width < 522 / image.height) {
      imgMultiple.value = 522 / image.width;
    } else {
      imgMultiple.value = 1;
    }
  }
  const imgWidth = imgMultiple.value * image.width;
  const imgHeight = imgMultiple.value * image.height;
  const imgX = (522 - imgWidth) / 2;
  const imgY = (522 - imgHeight) / 2;

  return {
    imgX,
    imgY,
    imgWidth,
    imgHeight,
  };
};

// 清除画布
const clearCanvas = () => {
  modelInProgress.value = "start";
};
</script>

<style lang="less" scoped>
.dialogClass {
  top: 500px;
}

.backGroundRemover-container {
  width: 100%;
  height: 452px;
  margin-top: 82px;
  display: flex;
  justify-content: center;
}

.back-ground-remover-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  margin-right: 118px;
}

.tool-title {
  font-family: 'Inter', Arial;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  color: #000000;
  margin-bottom: 52px;
}

.imageFilter-container {

  width: 100%;
  min-width: 1400px;
  position: relative;
}

.common-upload-box {
  width: 604px;
  height: 92px;
  border: 2px dashed #878787;
  border-radius: 6px;
}

.canvas-show-hover {
  position: absolute;
  width: 76px;
  height: 76px;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.45);
}

.canvas-show-container {
  margin: 0 auto;
  text-align: center;
  background: #f7f7f7;
  width: 522px;
  height: 522px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageFilter-preview-container {
  position: absolute;
  top: 0px;
  right: 100px;
}

.canvas-show-ul {
  list-style-type: none;
  width: 320px;
  height: 522px;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.canvas-show-ul::-webkit-scrollbar {
  background-color: transparent;
  /* or add it to the track */
  width: 7px;
}

.canvas-show-ul::-webkit-scrollbar-thumb {
  background: rgba(31, 35, 41, 0.3);
  border-radius: 3.5px;
  width: 7px !important;
  height: 135px !important;
}

.canvas-show-ul::-webkit-scrollbar-track {
  background: transparent;
}

.canvas-show-li {
  width: 76px;
  height: 76px;
  background: #ededed;
  border-radius: 6px;
  margin-right: 12px;
  margin-bottom: 42px;
  position: relative;
  display: flex;
  align-items: center;

  .filter-image {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    border-radius: 6px;
    align-items: center;
  }

  .filter-name {
    position: absolute;
    bottom: -22px;
    width: 100%;
    overflow: hidden; //超出一行文字自动隐藏 
    text-overflow: ellipsis; //文字隐藏后添加省略号
    white-space: nowrap; //强制不换行

    color: #1C1B1E;
    font-size: 12px;
    line-height: 14px;
  }
}

.canvas-show-li:nth-last-of-type(-n + 3) {
  margin-bottom: 0;
}

.canvas-show-image {
  height: inherit;
  -webkit-user-drag: none;
}

.canvas-show-li:hover {
  cursor: pointer;
}

.canvas-show-li canvas {
  border-radius: 4px;
}

.placeholder-box {
  width: 76px;
  display: inline-block;
  height: 1px;
  margin-right: 12px;
}

.position-box {
  position: relative;

  .buttons-container {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 80px 14px 80px;
    display: flex;
    justify-content: center;
    font-family: 'Inter', Arial;
    font-style: normal;
    font-weight: 400;

    .ant-btn {
      width: 120px;
      height: 36px;
      border-radius: 200px;
      color: #fff;
      border: none;
    }

    .ant-btn-default {
      background: rgba(0, 0, 0, 0.8);
      opacity: 0.8;
    }

    .ant-btn-primary {
      opacity: 1;
      background: linear-gradient(289deg, #632CFF 8.11%, #8E68FF 99.95%);

      &:hover {
        background: linear-gradient(289deg, #7646ff 18.5%, #9c7aff 99.95%);
      }
    }
  }

  @keyframes fadenum {
    0% {
      opacity: 0;
      transform: scaleX(0.5);
    }

    100% {
      opacity: 1;
      transform: scaleX(1);
    }
  }
}

.header {
  width: 100%;
  padding-left: 36px;
  margin-top: 25px;
}

.scroll-area {
  position: relative;
  margin: auto;
  width: 600px;
  height: 400px;
}

.loading-mask-box {
  width: 100%;
  height: 100%;
  background-color: #F7F7F7;
  opacity: 0.5;
  position: absolute;
  left: 0;
  top: 0;
  text-align: right;
}
</style>
